import axios from "axios";

export const headers = {
  "Tenant-Access-Token": "OTc2NWFlMDAwYjE2ODU3MmIyZThkZmVlNWVhY2ZlZjE=",
  "Tenant-Access-Key": "ByPEr8HMHABAkg",
};
export const BASE_URL = "https://immersive-game-india.confirmu.com";

export const api = axios.create({
  baseURL: BASE_URL,
  headers,
});
